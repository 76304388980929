<template>
  <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.66864 0C4.52285 0 0.336434 4.15495 0.336434 9.26207C0.336434 10.9139 0.778492 12.5308 1.61591 13.9459L0.0130636 18.674C-0.0167935 18.7622 0.0054142 18.8597 0.0706188 18.926C0.117687 18.9741 0.181534 19 0.246739 19C0.271723 19 0.296891 18.9962 0.321443 18.9884L5.25157 17.4218C6.6007 18.1426 8.12551 18.5231 9.6687 18.5231C14.814 18.5231 19 14.3686 19 9.26207C19 4.15495 14.814 0 9.66864 0ZM14.0588 10.9176L14.0124 10.8949C13.7642 10.7733 12.6128 10.2092 12.3905 10.1292C12.29 10.0931 12.1824 10.0579 12.0679 10.0579C11.8808 10.0579 11.7237 10.1511 11.6013 10.3342C11.4629 10.5399 11.0441 11.0295 10.9147 11.1757C10.8978 11.195 10.8747 11.2181 10.8609 11.2181C10.8485 11.2181 10.6341 11.1298 10.5692 11.1017C9.08332 10.4562 7.95547 8.90407 7.80082 8.64232C7.78087 8.60833 7.77809 8.59061 7.77763 8.58761C7.78305 8.56768 7.83302 8.51759 7.85881 8.49174C7.93417 8.41718 8.01602 8.31867 8.09503 8.22358C8.13247 8.17849 8.17003 8.13327 8.20685 8.09071C8.32148 7.95735 8.37274 7.85343 8.43189 7.73353L8.46292 7.67117C8.60752 7.38388 8.48402 7.14145 8.44411 7.06317C8.41921 7.01338 8.0754 6.18607 7.88452 5.72676L7.88338 5.72401C7.82368 5.58036 7.77913 5.47317 7.7643 5.4378C7.61477 5.07995 7.41718 4.91333 7.14261 4.91333L7.12924 4.91343C7.12673 4.9135 7.12422 4.91364 7.11969 4.91389C7.10952 4.91446 7.08918 4.91558 7.03576 4.91783C6.90566 4.92332 6.19717 5.0166 5.88392 5.21406C5.55173 5.42349 4.98975 6.09108 4.98975 7.26513C4.98975 8.32179 5.6603 9.31948 5.9482 9.69892C5.95415 9.70688 5.96426 9.72168 5.97847 9.74249C5.98133 9.74669 5.98436 9.75112 5.98755 9.7558C7.09011 11.366 8.46459 12.5593 9.85794 13.1159C11.1994 13.6517 11.8346 13.7137 12.1957 13.7137C12.3475 13.7137 12.469 13.7018 12.5761 13.6912L12.6441 13.6847C13.1075 13.6436 14.1258 13.116 14.3575 12.4722C14.54 11.9652 14.5881 11.4113 14.4667 11.2102C14.3835 11.0735 14.2402 11.0047 14.0588 10.9176Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Whatsapp extends Vue {}
</script>
